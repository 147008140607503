import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip as TooltipBS, A } from '@bootstrap-styled/v4'
import Button from '../../Buttons'

const P = styled.p`
    margin: 0;
`

class Tooltip extends React.PureComponent {
  state = {
    isOpen: false,
  }

 
  render() {
    const { text } = this.props;
    const { isOpen } = this.state; 
    return (
      <>
        <P>
          <A href="javascript:;" id="tooltip-example">
            <Button name="info" />
          </A>
        </P>
        <TooltipBS
          placement="right"
          isOpen={isOpen}
          target="tooltip-example"
          toggle={() => this.setState({ isOpen: !isOpen })}
        >
          {text}
        </TooltipBS>
      </>
    )
  }
}

export default Tooltip

import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import {  Col, Row } from '@bootstrap-styled/v4'
import { slugify } from '../../util/js/slugify'
import PreviewCard from '../PreviewCard'
import { numberWithDots } from '../../util/js/numberWithDots'
import { H2 } from '../Typography'
import PreviewCardAsLink from '../PreviewCard/PreviewCardAsLink'

const RecentObjectsBlock = ({ intl, data }) => {
  return (
    <>
      <Row className="mb-5">
        <Col xs={12}>
          <H2>{intl.formatMessage({ id: 'RECENT_OBJECTS' })}</H2>
        </Col>
      </Row>
      <Row>
        {data.map(({ node }) => {
          const image =
            node.realEstateImages &&
            node.realEstateImages[0] &&
            node.realEstateImages[0]._rawAsset

          const city =
            node.transfer &&
            node.transfer.cityArea &&
            node.transfer.cityArea.city &&
            node.transfer.cityArea.city.cityName

          const cityArea =
            node.transfer &&
            node.transfer.cityArea &&
            node.transfer.cityArea.cityArea

          const price =
            node.transfer &&
            node.transfer.price &&
            `€ ${numberWithDots(node.transfer.price)}`

          const url =
            node.transfer.cityArea &&
            node.transfer.cityArea.city &&
            node.transfer.cityArea.city.cityName &&
            node.transfer.street &&
            `/${slugify(node.transfer.cityArea.city.cityName)}/${slugify(
              node.transfer.street
            )}/${node.id.substring(0, 6)}`

          const rentOrBuyProperty = node.rentOrBuyProperty

          return (
            <Col md={4} key={node.id}>
              <PreviewCardAsLink link={url}>
                <PreviewCard
                  header={node.transfer.street}
                  subHeader={`${city}, ${cityArea}`}
                  previewImage={image}
                  imageLabel={price}
                  rentOrBuy={rentOrBuyProperty}
                />
              </PreviewCardAsLink>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default injectIntl(RecentObjectsBlock)

import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from '@bootstrap-styled/v4'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { H3, P } from '../../Typography'

const CharacteristicsWrapper = styled.div`
  margin-bottom: 3.5rem;

  .characteristic {
    padding: 0.875rem 0;
  }
  p {
    margin-bottom: 0;
  }
`

const OutdoorSpace = ({ intl, header, outdoorSpace }) => {
  const { balcony, gardenFacilities } = outdoorSpace

  return (
    <CharacteristicsWrapper>
      <H3 color="#b98752" className="mb-4">
        {header}
      </H3>
      {balcony && balcony.length > 0 && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'BALCONY' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>
            {balcony.map((balconyItem, index) => (
                <span key={balconyItem}>
                  {intl.formatMessage({ id: balconyItem })}
                  {balcony.length >= 1 &&
                    index !== balcony.length - 1 && <span>, </span>}
                </span>
              ))}
            </P>
          </Col>
        </Row>
      )}

      {gardenFacilities && gardenFacilities.length > 0 && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'GARDEN' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>
            {gardenFacilities.map((gardenItem, index) => (
                <span key={gardenItem}>
                  {intl.formatMessage({ id: gardenItem })}
                  {gardenFacilities.length >= 1 &&
                    index !== gardenFacilities.length - 1 && <span>, </span>}
                </span>
              ))}
            </P>
          </Col>
        </Row>
      )}

    </CharacteristicsWrapper>
  )
}

OutdoorSpace.propTypes = {
  header: PropTypes.string.isRequired,
}

export default injectIntl(OutdoorSpace)

import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl, IntlContextConsumer } from 'gatsby-plugin-intl'
import { Container, Col, Row } from '@bootstrap-styled/v4'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Section from '../components/UI/Section'
import ObjectDetailImageHeader from '../components/ObjectDetail/ObjectDetailImageHeader'
import ObjectDetailImageHeaderInfoRow from '../components/ObjectDetail/ObjectDetailImageHeaderInfoRow'
import PageHeader from '../components/PageHeader'
import ObjectDetailGallery from '../components/ObjectDetail/ObjectDetailGallery'
import Transfer from '../components/Characteristics/Transfer'
import Division from '../components/Characteristics/Division'
import OutdoorSpace from '../components/Characteristics/OutdoorSpace'
import StorageSpace from '../components/Characteristics/StorageSpace'
import Energy from '../components/Characteristics/Energy'
import Footer from '../components/Footer'
import { H2, H6 } from '../components/Typography'
import TextBlock from '../components/TextBlock'
import ContactMePersonal from '../components/ContactMePersonal'
import CollapseBlock from '../components/CollapseBlock'
import RecentObjectsBlock from '../components/RecentObjectsBlock'
import breakpoints from '../util/style/breakpoints'

export const propertyDetailQuery = graphql`
  query propertyDetailTemplateQuery($id: String!) {
    sanityRealEstateProperty(id: { eq: $id }) {
      _id
      publishedAt
      propertyUrl
      _rawDescriptionObject
      rentOrBuyProperty
      transfer {
        priceType
        priceTypeOptions
        costsRenter
        deposit
        housingSharingAllowed
        petsAllowed
        price
        rentalAgreement
        street
        availabilty
        cityArea {
          city {
            cityName
          }
          cityArea
        }
      }
      division {
        surfaceArea
        kitchen
        bedrooms
        bathrooms
        bathFacilities
        toilet
        numberOfFloors
      }
      outdoorSpace {
        balcony
        gardenFacilities
      }
      storageSpace {
        barnStorage
        parkingFacilities
      }
      energy {
        powerLabel
        isolation
      }
      realEstateImages {
        _key
        _rawAsset
        asset {
          url
        }
      }
    }
    allSanityRealEstateProperty(limit: 3) {
      edges {
        node {
          id
          rentOrBuyProperty
          transfer {
            cityArea {
              cityArea
              city {
                cityName
              }
            }
            street
            price
          }
          realEstateImages {
            _key
            _rawAsset
            asset {
              url
            }
          }
        }
      }
    }
  }
`

const TemplateWrapper = styled.div`
  padding-top: 6.938rem;

  ${breakpoints.md`
    padding-top: 12.5rem;
  `}

  ${breakpoints.xl`
    padding-top: 17rem;
  `}
`
const propertyDetailPage = ({ intl, data }) => {
  const {
    rentOrBuyProperty,
    _rawDescriptionObject,
    transfer,
    division,
    outdoorSpace,
    storageSpace,
    realEstateImages,
    energy,
  } = data.sanityRealEstateProperty

  const realEstateProperties =
    data.allSanityRealEstateProperty && data.allSanityRealEstateProperty.edges

  const galleryImages = []

  realEstateImages.forEach((image) => {
    if (image && image.asset && image.asset.url) {
      galleryImages.push(`${image.asset.url}?w=1200`)
    }
  })

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'site_title' })} />
      <TemplateWrapper>
        <PageHeader
          title={transfer.street}
          subTitle={`${transfer.cityArea.city.cityName}, ${transfer.cityArea.cityArea}`}
        />
        {transfer && realEstateImages[0] && (
          <Section>
            <Container>
              <ObjectDetailGallery showButtonOnly galleryImages={galleryImages}>
                <ObjectDetailImageHeader
                  rentOrBuyProperty={rentOrBuyProperty}
                  transfer={transfer}
                  imgHeader={realEstateImages[0]._rawAsset}
                />
              </ObjectDetailGallery>
              <ObjectDetailImageHeaderInfoRow
                division={division}
                outdoorSpace={outdoorSpace}
              />
            </Container>
          </Section>
        )}

        {_rawDescriptionObject && (
          <IntlContextConsumer>
            {({ language: currentLocale }) => (
              <Section>
                <Container>
                  <Row>
                    <Col md={10} lg={8}>
                      <CollapseBlock>
                        <TextBlock
                          blocks={_rawDescriptionObject[currentLocale]}
                        />
                      </CollapseBlock>
                    </Col>
                  </Row>
                </Container>
              </Section>
            )}
          </IntlContextConsumer>
        )}

        <Section>
          <Container>
            <Row className="padding-b-3-5r">
              <Col md={10} lg={8}>
                <H2 className="mb-4">
                  {intl.formatMessage({ id: 'CHARACTERISTICS' })}
                </H2>
                <H6>
                  {intl.formatMessage({ id: 'CHARACTERISTICS_SUB_TITLE' })}
                </H6>
              </Col>
            </Row>
            {transfer && (
              <Row>
                <Col xs={12} md={8}>
                  <Transfer
                    rentOrBuyProperty={rentOrBuyProperty}
                    header={intl.formatMessage({ id: 'TRANSFER' })}
                    transfer={transfer}
                  />
                </Col>
              </Row>
            )}
            {division && (
              <Row>
                <Col xs={12} md={8}>
                  <Division
                    header={intl.formatMessage({ id: 'DIVISION' })}
                    division={division}
                  />
                </Col>
              </Row>
            )}
            {outdoorSpace &&
              ((outdoorSpace.balcony && outdoorSpace.balcony.length > 0) ||
                (outdoorSpace.gardenFacilities &&
                  outdoorSpace.gardenFacilities.length > 0)) && (
                <Row>
                  <Col xs={12} md={8}>
                    <OutdoorSpace
                      header={intl.formatMessage({ id: 'OUTDOOR_SPACE' })}
                      outdoorSpace={outdoorSpace}
                    />
                  </Col>
                </Row>
              )}
            {storageSpace &&
              ((storageSpace.barnStorage &&
                storageSpace.barnStorage.length > 0) ||
                storageSpace.parkingFacilities) && (
                <Row>
                  <Col xs={12} md={8}>
                    <StorageSpace
                      header={intl.formatMessage({ id: 'STORAGE_SPACE' })}
                      storageSpace={storageSpace}
                    />
                  </Col>
                </Row>
              )}
            {energy && (
              <Row>
                <Col xs={12} md={8}>
                  <Energy
                    header={intl.formatMessage({ id: 'ENERGY' })}
                    energy={energy}
                  />
                </Col>
              </Row>
            )}
          </Container>
        </Section>
        {galleryImages && galleryImages.length > 0 && (
          <Section className="d-none d-md-flex">
            <Container>
              <H2 className="mb-5">
                {intl.formatMessage({ id: 'PHOTO_GALLERY' })}
              </H2>
              <ObjectDetailGallery galleryImages={galleryImages} />
            </Container>
          </Section>
        )}
        {realEstateProperties && (
          <Section>
            <Container>
              <RecentObjectsBlock data={realEstateProperties} />
            </Container>
          </Section>
        )}
        <Section>
          <Container>
            <ContactMePersonal
              header={intl.formatMessage({ id: 'contact.title' })}
              subTitle={intl.formatMessage({ id: 'contact.SUB_TITLE_AMBER' })}
              contactUs={intl.formatMessage({
                id: 'CONTACT_US',
              })}
            />
          </Container>
        </Section>
        <Footer />
      </TemplateWrapper>
    </Layout>
  )
}

export default injectIntl(propertyDetailPage)

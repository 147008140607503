import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from '@bootstrap-styled/v4'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { H3, P } from '../../Typography'

const CharacteristicsWrapper = styled.div`
  margin-bottom: 3.5rem;

  .characteristic {
    padding: 0.875rem 0;
  }
  p {
    margin-bottom: 0;
  }
`

const StorageSpace = ({ intl, header, storageSpace }) => {
  const { barnStorage, parkingFacilities } = storageSpace
  return (
    <CharacteristicsWrapper>
      <H3 color="#b98752" className="mb-4">
        {header}
      </H3>
      {barnStorage && barnStorage.length > 0 && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'BARN_STORAGE' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>
              {barnStorage.map((barnStorageItem, index) => (
                <span key={barnStorageItem}>
                  {intl.formatMessage({ id: barnStorageItem })}
                  {barnStorage.length >= 1 &&
                    index !== barnStorage.length - 1 && <span>, </span>}
                </span>
              ))}
            </P>
          </Col>
        </Row>
      )}
      {parkingFacilities && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">
              {intl.formatMessage({ id: 'PARKING_FACILITIES' })}
            </P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>
            {intl.formatMessage({ id: parkingFacilities })}
            </P>
          </Col>
        </Row>
      )}
    </CharacteristicsWrapper>
  )
}

StorageSpace.propTypes = {
  header: PropTypes.string.isRequired,
}

export default injectIntl(StorageSpace)

import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { Small, Col, Row } from '@bootstrap-styled/v4'
import breakpoints from '../../../util/style/breakpoints'
import { H6 } from '../../Typography'
import Icon from '../../Icons'

const ObjectAttribute = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1.5rem;
  ${breakpoints.md`
    margin-bottom: 0;
    align-items: center;
    flex-direction: row;
  `};

  .object-attribute-icon {
    svg {
      width: 24px;
      ${breakpoints.md`
        width: inherit;
      `};
    }
  }

  .object-attribute-text {
  }
  h6,
  small {
    margin-bottom: 0;
    ${breakpoints.md`
     margin-left: 2rem;
    `};
  }
`

const ObjectDetailImageHeaderInfoRow = ({
  intl,
  division,
  outdoorSpace,
}) => {

  return (
    <>
      <Row className="mt-5">
        {division && division.surfaceArea && (
          <Col xs={6} md={3}>
            <ObjectAttribute>
              <div className="object-attribute-icon">
                <Icon width="48" name="livingSpace" />
              </div>
              <div className="object-attribute-text">
                <Small
                  color="muted"
                  className="text-uppercase font-weight-bold"
                >
                  {intl.formatMessage({ id: 'LIVING_AREA' })}
                </Small>
                <H6>{division.surfaceArea} m²</H6>
              </div>
            </ObjectAttribute>
          </Col>
        )}
        {division && division.bedrooms && (
          <Col xs={6} md={3}>
            <ObjectAttribute>
              <div className="object-attribute-icon">
                <Icon width="48" name="bedroom" />
              </div>
              <div className="object-attribute-text">
                <Small
                  color="muted"
                  className="text-uppercase font-weight-bold"
                >
                  {intl.formatMessage({ id: 'BEDROOM' })}(s)
                </Small>
                <H6>{division.bedrooms}</H6>
              </div>
            </ObjectAttribute>
          </Col>
        )}
        {division && division.bathrooms && (
          <Col xs={6} md={3}>
            <ObjectAttribute>
              <div className="object-attribute-icon">
                <Icon width="48" name="bathroom" />
              </div>
              <div className="object-attribute-text">
                <Small
                  color="muted"
                  className="text-uppercase font-weight-bold"
                >
                  {intl.formatMessage({ id: 'BATHROOM' })}(s)
                </Small>
                <H6>{division.bathrooms}</H6>
              </div>
            </ObjectAttribute>
          </Col>
        )}
        {((outdoorSpace && outdoorSpace.gardenFacilities) ||
          (outdoorSpace && outdoorSpace.balcony)) && (
          <Col xs={6} md={3}>
            <ObjectAttribute>
              <div className="object-attribute-icon">
                <Icon width="48" name="outdoorSpace" />
              </div>
              <div className="object-attribute-text">
                <Small
                  color="muted"
                  className="text-uppercase font-weight-bold"
                >
                  {intl.formatMessage({ id: 'OUTDOOR_SPACE' })}
                </Small>

                <H6>
                  {outdoorSpace.gardenFacilities &&
                    outdoorSpace.gardenFacilities.length > 0 &&
                    intl.formatMessage({ id: 'GARDEN' })}
                  {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                  {outdoorSpace.gardenFacilities &&
                    outdoorSpace.gardenFacilities.length > 0 &&
                    outdoorSpace.balcony &&
                    outdoorSpace.balcony.length > 0 && <>{', '}</>}
                  {outdoorSpace.balcony &&
                    outdoorSpace.balcony.length > 0 &&
                    intl.formatMessage({ id: 'BALCONY' })}
                </H6>
              </div>
            </ObjectAttribute>
          </Col>
        )}
      </Row>
    </>
  )
}

export default injectIntl(ObjectDetailImageHeaderInfoRow)

import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from '@bootstrap-styled/v4'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { H3, P } from '../../Typography'

const CharacteristicsWrapper = styled.div`
  margin-bottom: 3.5rem;

  .characteristic {
    padding: 0.875rem 0;
  }
  p {
    margin-bottom: 0;
  }
`

const Division = ({ intl, header, division }) => {
  const {
    surfaceArea,
    kitchen,
    bedrooms,
    bathrooms,
    bathFacilities,
    toilet,
    numberOfFloors,
  } = division

  return (
    <CharacteristicsWrapper>
      <H3 color="#b98752" className="mb-4">
        {header}
      </H3>
      {surfaceArea && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'SURFACE_AREA' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>
              {surfaceArea}
              m²
            </P>
          </Col>
        </Row>
      )}
      {kitchen && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'KITCHEN' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>{intl.formatMessage({ id: kitchen })}</P>
          </Col>
        </Row>
      )}

      {bedrooms && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">
              {intl.formatMessage({ id: 'NUMBER_OF_BEDROOMS' })}
            </P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>{bedrooms}</P>
          </Col>
        </Row>
      )}

      {bathrooms && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">
              {intl.formatMessage({ id: 'NUMBER_OF_BATHROOMS' })}
            </P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>{bathrooms}</P>
          </Col>
        </Row>
      )}

      {bathFacilities && bathFacilities.length > 0 && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">
              {intl.formatMessage({ id: 'BATH_FACILITIES' })}
            </P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>
              {bathFacilities.map((bathFacilityItem, index) => (
                <span key={bathFacilityItem}>
                  {intl.formatMessage({ id: bathFacilityItem })}
                  {bathFacilities.length >= 1 &&
                    index !== bathFacilities.length - 1 && <span>, </span>}
                </span>
              ))}
            </P>
          </Col>
        </Row>
      )}

      {toilet && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'TOILET' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>{toilet}</P>
          </Col>
        </Row>
      )}

      {numberOfFloors && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">
              {intl.formatMessage({ id: 'NUMBER_OF_FLOORS' })}
            </P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>{numberOfFloors}</P>
          </Col>
        </Row>
      )}
    </CharacteristicsWrapper>
  )
}

Division.propTypes = {
  header: PropTypes.string.isRequired,
}

export default injectIntl(Division)

import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from '@bootstrap-styled/v4'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { H3, P } from '../../Typography'
import Tooltip from '../../UI/Tooltip'
import { numberWithDots } from '../../../util/js/numberWithDots'

const CharacteristicsWrapper = styled.div`
  margin-bottom: 3.5rem;

  .characteristic {
    padding: 0.875rem 0;
  }
  p {
    margin-bottom: 0;
  }
`

const TextWithBg = styled(P)`
  font-weight: 500;
  padding: 0 0.5rem;
  background-color: ${(props) => props.theme['$brand-primary300']};
  color: ${(props) => props.theme['$gray-dark']};
`

const Transfer = ({ intl, header, rentOrBuyProperty, transfer }) => {
  const {
    price,
    priceType,
    priceTypeOptions,
    deposit,
    costsRenter,
    rentalAgreement,
    availabilty,
    petsAllowed,
    housingSharingAllowed,
  } = transfer

  const PriceToolTipText = priceTypeOptions
    .map((option) => intl.formatMessage({ id: option }))
    .join(', ')

  return (
    <CharacteristicsWrapper>
      <H3 color="#b98752" className="mb-4">
        {header}
      </H3>
      {(price === 0 || price) && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            {rentOrBuyProperty === 'RENT_PROPERTY' && (
              <P color="#888888">{intl.formatMessage({ id: 'RENT_PRICE' })}</P>
            )}
            {rentOrBuyProperty === 'BUY_PROPERTY' && (
              <P color="#888888">{intl.formatMessage({ id: 'BUY_PRICE' })}</P>
            )}
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>
              € {numberWithDots(price)}{' '}
              {rentOrBuyProperty === 'RENT_PROPERTY' && (
                <>
                  {intl.formatMessage({ id: 'per_month' })}{' '}
                  {priceType && intl.formatMessage({ id: priceType })}
                </>
              )}
              {rentOrBuyProperty === 'BUY_PROPERTY' && (
                <>{intl.formatMessage({ id: 'COST_BUYER' })}</>
              )}
            </P>
            {priceTypeOptions && priceTypeOptions.length > 0 && (
              <Tooltip className="d-none d-md-flex" text={PriceToolTipText} />
            )}
          </Col>
        </Row>
      )}
      {(deposit === 0 || deposit) && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'DEPOSIT' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>
              € {numberWithDots(deposit)} {intl.formatMessage({ id: 'ONCE' })}
            </P>
          </Col>
        </Row>
      )}

      {(costsRenter === 0 || costsRenter) && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'COST_RENTER' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>€ {numberWithDots(costsRenter)}</P>
          </Col>
        </Row>
      )}

      {rentalAgreement && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">
              {intl.formatMessage({ id: 'RENTAL_AGREEMENT' })}
            </P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>{intl.formatMessage({ id: rentalAgreement })}</P>
          </Col>
        </Row>
      )}

      {availabilty && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'AVAILABILITY' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <TextWithBg>{intl.formatMessage({ id: availabilty })}</TextWithBg>
          </Col>
        </Row>
      )}

      {petsAllowed && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'PETS' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>{intl.formatMessage({ id: petsAllowed })}</P>
          </Col>
        </Row>
      )}

      {housingSharingAllowed && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'HOUSE_SHARING' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>{intl.formatMessage({ id: housingSharingAllowed })}</P>
          </Col>
        </Row>
      )}
    </CharacteristicsWrapper>
  )
}

Transfer.propTypes = {
  header: PropTypes.string.isRequired,
}

export default injectIntl(Transfer)

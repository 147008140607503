import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from '@bootstrap-styled/v4'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { H3, P } from '../../Typography'
import EnergyLabel from '../../ObjectDetail/EnergyLabel'

const CharacteristicsWrapper = styled.div`

  .characteristic {
    padding: 0.875rem 0;
  }
  p {
    margin-bottom: 0;
  }
`

const Energy = ({ intl, header, energy }) => {
  const { powerLabel, isolation } = energy

  return (
    <CharacteristicsWrapper>
      <H3 color="#b98752" className="mb-4">
        {header}
      </H3>
      {powerLabel && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'ENERGY_LABEL' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <EnergyLabel label={powerLabel} />
          </Col>
        </Row>
      )}
      {isolation && isolation.length > 0 && (
        <Row className="border-bottom-2p no-gutters characteristic">
          <Col xs={6} md="4" className="d-flex align-items-center">
            <P color="#888888">{intl.formatMessage({ id: 'INSULATION' })}</P>
          </Col>
          <Col xs={6} md="8" className="d-flex align-items-center">
            <P>
              {isolation.map((item, index) => (
                <span key={item}>
                  {intl.formatMessage({ id: item })}
                  {isolation.length >= 1 &&
                    index !== isolation.length - 1 && <span>, </span>}
                </span>
              ))}
            </P>
          </Col>
        </Row>
      )}
    </CharacteristicsWrapper>
  )
}

Energy.propTypes = {
  header: PropTypes.string.isRequired,
}

export default injectIntl(Energy)

import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { injectIntl } from 'gatsby-plugin-intl'
import { Collapse as CollapseBs } from '@bootstrap-styled/v4'
import Icon from '../Icons'
import { P } from '../Typography'

const Collapse = styled(CollapseBs)`
  &:not(.show) {
    display: block;
    height: 12rem;
    overflow: hidden;
  }
`

const ReadMoreButton = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  p {
    margin: 0 0 0 1rem;
  }

  svg {
    transform: ${(props) => props.isOpen && 'rotate(180deg)'};
  }
`

const CollapseBlock = ({ intl, children }) => {
  let [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {children && children.props.blocks.length > 4 ? (
        <>
          <Collapse isOpen={isOpen}>{children}</Collapse>
          <ReadMoreButton
            className="mt-4"
            onClick={() => setIsOpen((isOpen = !isOpen))}
            isOpen={isOpen}
          >
            <Icon width="16" fill="#b98752" name="chevron" />
            <P color="#b98752">{intl.formatMessage({ id: 'READ_MORE' })}</P>
          </ReadMoreButton>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

CollapseBlock.propTypes = {}

export default injectIntl(CollapseBlock)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app
import { Col, Row } from '@bootstrap-styled/v4'

const ShowSlideShowButton = styled.div`
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
  }
  img {
    &:hover {
      opacity: 0.6 !important;
    }
  }
  &:focus {
    outline: none;
  }
`
const Gallery = styled.div`
  display: flex;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
  }
  img:hover {
    opacity: 0.6;
  }
  .gallery-left {
    flex-grow: 2;
    flex-basis: 65%;
    margin-right: 0.25rem;
    max-height: 540px;
  }
  .gallery-right {
    flex-grow: 1;
    flex-basis: 35%;
    margin-left: 0.25rem;
    display: flex;
    flex-direction: column;
    &__top {
      margin-bottom: 0.25rem;
      flex-grow: 1;
      flex-basis: 50%;
    }
    &__bottom {
      margin: 0 -0.25rem -0.25rem -0.25rem;
      overflow: hidden;
      /* margin-top: 0.25rem; */
      flex-grow: 1;
      flex-basis: 50%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      &__grid {
        /* margin: 0.25rem; */
        flex-grow: 1;
        flex-basis: 50%;
        display: flex;
      }
      &__item {
        flex-grow: 1;
        flex-basis: 50%;
        padding: 0.25rem;
      }
    }
  }

  .gallery-interaction {
    cursor: pointer;
  }
`

// const OpenGallery = styled.div`
//   color: grey;
//   font-weight: 500;
// `

const BackgroundImage = styled.div`
  height: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
`

export default class ObjectDetailGallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state
    const { showButtonOnly, children, galleryImages } = this.props
    const slideShow = (
      <>
        <Lightbox
          wrapperClassName="object-detail-lightbox"
          mainSrc={galleryImages[photoIndex]}
          nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
          prevSrc={
            galleryImages[
              (photoIndex + galleryImages.length - 1) % galleryImages.length
            ]
          }
          onCloseRequest={() => this.setState({ isOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex:
                (photoIndex + galleryImages.length - 1) % galleryImages.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % galleryImages.length,
            })
          }
          enableZoom={false}
        />
      </>
    )

    if (showButtonOnly) {
      return (
        <>
          <ShowSlideShowButton
            role="button"
            tabIndex={0}
            onClick={() => this.setState({ isOpen: true, photoIndex: 0 })}
            onKeyDown={() => this.setState({ isOpen: true, photoIndex: 0 })}
          >
            {children}
          </ShowSlideShowButton>
          {isOpen && slideShow}
        </>
      )
    }
    return (
      <div>
        <Row>
          <Col>
            <Gallery>
              {galleryImages[0] && (
                <div className="gallery-left gallery-interaction">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      this.setState({ isOpen: true, photoIndex: 0 })
                    }
                    onKeyDown={() =>
                      this.setState({ isOpen: true, photoIndex: 0 })
                    }
                  >
                    <img src={galleryImages[0]} alt="alt" />
                  </div>
                </div>
              )}
              {galleryImages[1] && (
                <div className="gallery-right">
                  <div className="gallery-right__top gallery-interaction">
                    <BackgroundImage
                      image={galleryImages[1]}
                      onClick={() =>
                        this.setState({ isOpen: true, photoIndex: 1 })
                      }
                    />
                  </div>
                  <div className="gallery-right__bottom">
                    <div className="gallery-right__bottom__grid">
                      {galleryImages[2] && (
                        <div className="gallery-right__bottom__item gallery-interaction">
                          <BackgroundImage
                            image={galleryImages[2]}
                            onClick={() =>
                              this.setState({ isOpen: true, photoIndex: 2 })
                            }
                          />
                        </div>
                      )}
                      {galleryImages[3] && (
                        <div className="gallery-right__bottom__item gallery-interaction">
                          <BackgroundImage
                            image={galleryImages[3]}
                            onClick={() =>
                              this.setState({ isOpen: true, photoIndex: 3 })
                            }
                          />
                        </div>
                      )}
                    </div>
                    {galleryImages[4] && (
                      <div className="gallery-right__bottom__grid">
                        {galleryImages[4] && (
                          <div className="gallery-right__bottom__item gallery-interaction">
                            <BackgroundImage
                              image={galleryImages[4]}
                              onClick={() =>
                                this.setState({ isOpen: true, photoIndex: 4 })
                              }
                            />
                          </div>
                        )}
                        {galleryImages[5] && (
                          <div className="gallery-right__bottom__item gallery-interaction">
                            <BackgroundImage
                              image={galleryImages[5]}
                              onClick={() =>
                                this.setState({ isOpen: true, photoIndex: 5 })
                              }
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Gallery>
          </Col>
        </Row>

        {isOpen && slideShow}
      </div>
    )
  }
}

ObjectDetailGallery.propTypes = {
  showButtonOnly: PropTypes.bool,
  children: PropTypes.node,
}

ObjectDetailGallery.defaultProps = {
  showButtonOnly: false,
  children: 'Open gallery',
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Label = styled.div`
  background-color: ${props => props.bgColor};
  padding: 0.1rem 1.125rem 0.1rem 0.5rem;
  height: 28px;
  strong {
    color: white;
  }
`

const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid ${props => props.bgColor};
`

const EnergyLabel = ({ label }) => {
  let bgColor
  switch (label) {
    case 'A++++':
      bgColor = '#1abc05'
      break
    case 'A+++':
      bgColor = '#1abc05'
      break
    case 'A++':
      bgColor = '#1abc05'
      break
    case 'A+':
      bgColor = '#1abc05'
      break
    case 'A':
      bgColor = '#1abc05'
      break
    case 'B':
      bgColor = '#6fc708'
      break
    case 'C':
      bgColor = '#94c403'
      break
    case 'D':
      bgColor = '#fdc819'
      break
    case 'E':
      bgColor = '#f5a623'
      break
    case 'F':
      bgColor = '#e8660d'
      break
    case 'G':
      bgColor = '#d0021b'
      break
    default:
      bgColor = 'black'
  }

  return (
    <>
      <Label bgColor={bgColor}>
        <strong>{label}</strong>
      </Label>
      <ArrowRight bgColor={bgColor} />
    </>
  )
}

EnergyLabel.propTypes = {
  label: PropTypes.string.isRequired,
}

export default EnergyLabel
